import { Link } from '@inertiajs/react';
import classNames from 'classnames';
import {PaginationMeta} from "@/types";

type PaginationLink = {
  active?: boolean;
  label: string;
  url: string | null;
};

const buildLinks = (meta: PaginationMeta): PaginationLink[] => {
  const url = (pageNumber: string | number | null) => {
    return pageNumber
      ? meta.scaffold_url.replace(/__pagy_page__/, pageNumber.toString())
      : null;
  }

  return [
    {
      label: 'Previous',
      url: url(meta.prev),
    },
    ...meta.sequels['0'].map((page) => {
      return {
        label: page,
        url: url(page),
        active: meta.page.toString() == page,
      };
    }),
    {
      label: 'Next',
      url: url(meta.next),
    },
  ];
}

export default function Pagination({meta}: {meta: PaginationMeta}) {
  const links = buildLinks(meta);
  console.log({links});
  /**
   * If there are only 3 links, it means there are no previous or next pages.
   * So, we don't need to render the pagination.
   */
  if (links.length === 3) return null;

  return (
    <div className="flex flex-wrap mt-6 -mb-1">
      {links?.map(link => {
        return link?.url === null ? (
          <PageInactive key={link.label} label={link.label} />
        ) : (
          <PaginationItem key={link.label} {...link} />
        );
      })}
    </div>
  );
}

function PaginationItem({ active, label, url }: PaginationLink) {
  const className = classNames(
    [
      'mr-1 mb-1',
      'px-4 py-3',
      'border border-solid border-gray-300 rounded',
      'text-sm',
      'hover:bg-white',
      'focus:outline-none focus:border-indigo-700 focus:text-indigo-700'
    ],
    {
      'bg-white': active
    }
  );

  /**
   * Note: In general you should be aware when using `dangerouslySetInnerHTML`.
   *
   * In this case, `label` from the API is a string, so it's safe to use it.
   * It will be either `&laquo; Previous` or `Next &raquo;`
   */
  return (
    <Link className={className} href={url as string}>
      <span dangerouslySetInnerHTML={{ __html: label }}></span>
    </Link>
  );
}

function PageInactive({ label }: Pick<PaginationLink, 'label'>) {
  const className = classNames(
    'mr-1 mb-1 px-4 py-3 text-sm border rounded border-solid border-gray-300 text-gray-400'
  );

  /**
   * Note: In general you should be aware when using `dangerouslySetInnerHTML`.
   *
   * In this case, `label` from the API is a string, so it's safe to use it.
   * It will be either `&laquo; Previous` or `Next &raquo;`
   */
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: label }} />
  );
}
