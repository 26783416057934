import MainMenuItem from '@/Components/Menu/MainMenuItem';
import { Building, CircleGauge, Printer, Users } from 'lucide-react';
import * as routes from '@/routes';

interface MainMenuProps {
  className?: string;
}

export default function MainMenu({ className }: MainMenuProps) {
  return (
    <div className={className}>
      <MainMenuItem
        text="Dashboard"
        link={routes.root_path()}
        icon={<CircleGauge size={16} />}
      />
      <MainMenuItem
        text="Organizations"
        link={routes.organizations_path()}
        icon={<Building size={16} />}
      />
      <MainMenuItem
        text="Contacts"
        link={routes.contacts_path()}
        icon={<Users size={16} />}
      />
      <MainMenuItem
        text="Reports"
        link={routes.reports_path()}
        icon={<Printer size={16} />}
      />
    </div>
  );
}
