import {Link, usePage} from '@inertiajs/react';
import classNames from 'classnames';

interface MainMenuItemProps {
  icon?: React.ReactNode;
  link: string;
  text: string;
}

export default function MainMenuItem({ icon, link, text }: MainMenuItemProps) {
  const {url} = usePage()
  // const isActive = false;
  // TODO: const isActive = route().current(link + '*');
  const urlPath = url.split('?')[0];
  const isActive = link == "/" ? urlPath === link : urlPath.startsWith(link);

  const iconClasses = classNames(['mr-2 w-4 h-4'],{
    'text-white': isActive,
    'text-indigo-400 group-hover:text-white': !isActive
  });

  const textClasses = classNames({
    'text-white': isActive,
    'text-indigo-300 group-hover:text-white': !isActive
  });

  return (
    <div className="mb-4">
      <Link
        href={link}
        className="group flex items-center py-3"
      >
        <div className={iconClasses}>{icon}</div>
        <div className={textClasses}>{text}</div>
      </Link>
    </div>
  );
}
